import React, { useEffect, useState } from "react";
import Navbar from "components/Navbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import Footer from "components/Footer.js";
import TextGrid from "components/TextGrid.js";

// import Testimonials from "components/Testimonials.js";
import KeyTopics from "components/KeyTopics.js";

import Stats2 from "components/Stats2.js";
import Stats from "components/Stats.js";
import SpeakerCard from "components/SpeakerCard.js";
import Sponsor from "components/Sponsor.js";
// import { speakers, sponsors } from "./content";
import Slider from 'react-slick';


import "../assets/demo/xs.css";
import "../assets/demo/sm.css";
import "../assets/demo/md.css";
import "../assets/demo/lg.css";
import "../assets/demo/xl.css";
import "../assets/demo/xxl.css";
import { Button } from "reactstrap";


function Home() {
  const [speakers, setspeakers] = useState([])
  const [speakersVIP, setspeakersVIP] = useState([])
  const [sponsors, setsponsors] = useState([])
  useEffect(() => {
    fetch(`https://api.khaleejtimesevents.com/futuresec/data.json?v=3rdOct${new Date()}`, {
      method: "get",
    })
      .then((response) => response.json())
      .then((data) => {
        setspeakersVIP(data.speakersVIP);
        setspeakers(data.speakers);
        setsponsors(data.sponsors);
      })
      .catch((err) => console.log(err));
  }, []);

  document.documentElement.classList.remove("nav-open");

  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  // Images for Primary Industries
  const industries = [
    `/images/govPubSec.png`,
    `/images/bfsi.png`,
    `/images/itTele.png`,
    `/images/healthcare.png`,
    `/images/manufacturing.png`,
    `/images/retail.png`,
    `/images/energyUtilities.png`,
    `/images/education.png`,
  ];

  // Sponsers
  const sponserd = [
    {
      image: '/images/bs-1.svg',
      text: 'CYBERSECURITY SOLUTION PROVIDERS'
    },
    {
      image: '/images/bs-2.svg',
      text: 'IT SERVICE MANAGEMENT COMPANIES'
    },
    {
      image: '/images/bs-3.svg',
      text: 'CLOUD SERVICE PROVIDERS'
    },
    {
      image: '/images/bs-4.svg',
      text: 'CONSULTING FIRMS'
    },
    {
      image: '/images/bs-5.svg',
      text: 'LAW FIRMS SPECIALIZING IN CYBER LAW'
    },
    {
      image: '/images/bs-6.svg',
      text: 'HARDWARE AND SOFTWARE VENDORS'
    },
    {
      image: '/images/bs-7.svg',
      text: 'CYBERSECURITY TRAINING AND EDUCATION PROVIDERS'
    },
  ];

  // Top industry challenges
  const challenges = [
    {
      image: '/images/gs-1.svg',
      text: 'Rising Cybersecurity Skill Gap'
    },
    {
      image: '/images/gs-2.svg',
      text: 'Sophistication of Cyber Attacks'
    },
    {
      image: '/images/gs-3.svg',
      text: 'Ensuring Cloud Security'
    },
    {
      image: '/images/gs-4.svg',
      text: 'Managing Third-party Risks'
    },
    {
      image: '/images/gs-5.svg',
      text: 'Staying Compliant with Evolving Regulations'
    },
    {
      image: '/images/gs-6.svg',
      text: 'Balancing Security with User Experience'
    },
    {
      image: '/images/gs-7.svg',
      text: 'Protecting Against Insider Threats'
    },
  ];

  // Top Industry Trends
  const trends = [
    {
      image: '/images/tit-1.svg',
      text: `Increasing Adoption of AI 
      and Machine Learning`,
    },
    {
      image: '/images/tit-2.svg',
      text: `The Rise of 
      Quantum Computing`,
    },
    {
      image: '/images/tit-3.png',
      text: `Enhanced Cloud 
      Security Measures`,
    },
    {
      image: '/images/tit-4.svg',
      text: `Greater Emphasis 
      on Zero Trust Security`,
    },
    {
      image: '/images/tit-5.svg',
      text: `Advanced Threat 
      Detection and Response`,
    },
    {
      image: '/images/tit-6.svg',
      text: `Integration of Blockchain 
      for Security`,
    },
    {
      image: '/images/tit-7.svg',
      text: `Growing Focus on 
      Data Privacy and 
      Compliance`,
    },
  ];

  // Event Agenda
  const eventAgendas = [
    {
      time: '08:00',
      session: '<h3>Registration & Welcome Coffee</h3>',
    },
    {
      time: '09:00',
      session: '<h3>Welcome Note by KT Events</h3>',
    },
    {
      time: '09:05<br /><small>(15 min)</small>',
      session: `<h4>Opening Keynote | Fortifying The Digital Fortress: Cyber Resilience as a Strategic Pillar for the UAE:</h4>
        <ul>
          <li>The Strategic Importance of Cybersecurity.</li>
          <li>Current Threat Landscape.</li>
          <li>Future-Proofing Cybersecurity.</li>
        </ul>`,
    },
    {
      time: `09:20<br /><small>(40 min)</small>`,
      session: `<h4>Panel Discussion | The Governance Code: Navigating Compliance in a Digital Age:</h4>
        <ul>
          <li>Risk Management & Data Protection.</li>
          <li>Legal & Regulatory Adherence.</li>
          <li>Operational Efficiency & Trust.</li>
        </ul>`,
    },
    {
      time: `10:00<br /><small>(40 min)</small>`,
      session: `<h4>Panel Discussion | The Rapid Response Blueprint: Managing Cyber Crises with Confidence:</h4>
        <ul>
          <li>Timely Detection & Mitigation.</li>
          <li>Structured Response Plans.</li>
          <li>Resilience & Continuity.</li>
        </ul>`,
    },
    {
      time: `10:40<br /><small>(15 min)</small>`,
      session: `<h4>Keynote | Cloud Guardians: Best Practices for Securing Digital Skies:</h4>
        <ul>
          <li>Implement Strong Access Controls.</li>
          <li>Data Encryption & Regular Security.</li>
          <li>Audits & Compliance Checks.</li>
          <li>Continuous Monitoring & Incident Response.</li>
        </ul>`,
    },
    {
      time: `10:55`,
      session: `<h3>Coffee & Networking Break</h3> `,
    },
    {
      time: `11:10<br /><small>(40 min)</small>`,
      session: `<h4>Panel Discussion | Breaking Barriers: Empowering Women in Cybersecurity Leadership:</h4>
        <ul>
          <li>Promoting Gender Diversity.</li>
          <li>Educational Initiatives.</li>
          <li>Awareness & Advocacy.</li>
        </ul>`,
    },
    {
      time: `11:50<br /><small>(15 min)</small>`,
      session: `<h4>Keynote Presentation | The IoT Shield: Securing a Hyperconnected World:</h4>
        <ul>
          <li>Implement Strong Authentication & Access Controls.</li>
          <li>Encrypt Data Transmission & Storage.</li>
          <li>Regularly Update & Patch Devices.</li>
          <li>Monitor & Analyse Network Traffic.</li>
        </ul>`,
    },
    {
      time: `12:05<br /><small>(40 min)</small>`,
      session: `<h4>Panel Discussion | Privacy First: Navigating Data Protection in a Complex Regulatory Landscape:</h4>
        <ul>
          <li>Establishment of Legal Frameworks.</li>
          <li>Data Security & Privacy Strategies.</li>
          <li>Minimizing Data Collection & Usage.</li>
        </ul>`,
    },
    {
      time: `12:45`,
      session: `<h3>Prayer & Networking Break</h3>`,
    },
    {
      time: `13:05<br /><small>(15 min)</small>`,
      session: `<h4>Keynote Presentation | DevSecOps Unleashed: Embedding Security in Every Step of Development:</h4>
        <ul>
          <li>Integrating security practices within the DevOps process to achieve continuous security.</li>
          <li>Embedding Security in CI/CD Pipelines.</li>
          <li>Continuous Security Testing & Proactive Security Measures.</li>
        </ul>`,
    },
    {
      time: `13:20<br /><small>(40 min)</small>`,
      session: `<h4>Panel Discussion | Disrupting The Attack Chain: Integrated Threat Protection Strategies:</h4>
        <ul>
          <li>Leverage Threat Intelligence.</li>
          <li>Implement Layered Security.</li>
          <li>Automate Security Responses.</li>
        </ul>`,
    },
    {
      time: `14:00<br /><small>(15 min)</small>`,
      session: `<h4>Keynote | Trust, But Verify: Mastering Zero Trust Architecture:</h4>
        <ul>
          <li>Strong Identity Verification.</li>
          <li>Continuous Monitoring & Validation.</li>
          <li>Enhanced Device Visibility & Network Segmentation.</li>
        </ul>`,
    },
    {
      time: `14:15<br /><small>(15 min)</small>`,
      session: `<h4>Keynote | The Resilience Blueprint: Cybersecurity in the Age of Digital Transformation:</h4>
        <ul>
          <li>Enhanced Cybersecurity Measures.</li>
          <li>Integration of AI in Cyber Defence.</li>
          <li>Collaboration & Knowledge Sharing.</li>
        </ul>`,
    },
    {
      time: `14:30`,
      session: `<h3>Closing Remarks by KT Events</h3>`,
    },
    {
      time: `14:35`,
      session: `<h3>Networking Luncheon & End of the Conference</h3>`,
    },
  ];

  // Supporting Associations
  const supportings = [
    `/images/supporting/supporting-1.jpg`,
    `/images/supporting/supporting-2.png`,
  ];

  const supportingSettings = {
    classNames: '',
    dots: false,
    infinite: false,
    speed: 500,
    spaceBetween: 30,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
          infinite: true,
        }
      }
    ]
  };



  return (
    <>
      <div id="outer-container">
        <div
          style={{
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            // backgroundImage: "url(" + require("assets/images/BG.png") + ")",
            // backgroundImage: "url(" + require("assets/images/bg-1.png") + ")",
            backgroundImage: "url(" + require("assets/images/bg-2.png") + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        ></div>

        <Navbar home={true} />
        <section id="home" />
        <LandingPageHeader />
        <main id="page-wrap">
          <section id="about" />
          <Stats />
          <TextGrid />
          <Stats2 />
          <KeyTopics />


          {/* ----- Primary Industries ----- */}
          <section className="section"
            style={{
              overflow: "hidden",
              background: "rgb(26, 34, 89)",
            }}
          >
            <h1 className="text-white text-center section-title mb-5">Primary Industries</h1>

            <div className="pi-div">
              {
                industries.map((industry, index) => (
                  <img src={industry} className="pi-div-img col px-0" key={index} alt="govtpubsec" />
                ))
              }
            </div>
          </section>

          {/* ----- Sponsor ----- */}
          <section className="d-none section py-5" style={{ background: 'transparent' }}>
            <h1 className="text-center section-title pb-5 px-3" style={{ color: 'rgb(87, 200, 232)' }}>Who should sponsor?</h1>

            <div className="container row mx-auto">
              {
                sponserd.map((spon, index) => (
                  <div className="d-flex align-items-center col-12 col-md-6 col-lg-4 px-0 mb-3 mb-md-5" key={index}>
                    <img src={spon.image} className="me-3" style={{ maxWidth: '65px' }} alt="sdfsd" />

                    <span className="text-white text-600"
                      style={{ borderLeft: '3px solid rgb(164, 80, 139)', marginLeft: '15px', paddingInline: '15px' }}
                    >{spon.text}</span>
                  </div>
                ))
              }
            </div>
          </section>

          {/* ----- Top Industry Trends in 2024 ----- */}
          <section className="section" style={{ backgroundColor: 'rgb(26 34 89)' }}>
            <h1 className="text-center section-title px-3 pb-5 mb-5 text-white">Top Industry Trends in 2024</h1>

            <div className="row justify-content-center w-100 mx-auto">
              {
                trends.map((trend, index) => (
                  // Image & Text
                  <div className='text-center col-12 col-sm-6 col-lg-4' key={index}>
                    <img src={trend.image} className="tit-img mb-5" alt="image1" />

                    <p className="text-white tit-text-1 text-center">{trend.text}</p>

                    {(index !== 6) && <hr className="hr tit-hr my-5" />}
                  </div>
                ))
              }
            </div>
          </section>

          {/* ----- Top Industry Challenges ----- */}
          <section className="d-none section py-5" style={{ background: 'transparent' }}>
            <h1 className="text-center section-title pt-5 pb-5" style={{ color: 'rgb(87, 200, 232)' }}>Top Industry Challenges</h1>

            <div className="container row mx-auto">
              {
                challenges.map((challenge, index) => (
                  <div className="d-flex align-items-center col-12 col-md-6 col-lg-4 px-0 mb-3 mb-md-5" key={index}>
                    <img src={challenge.image} className="me-3" alt="sdfsd" />

                    <h3 className="text-white text-600"
                      style={{ borderLeft: '3px solid rgb(164, 80, 139)', marginLeft: '15px', paddingInline: '15px' }}
                    >{challenge.text}</h3>
                  </div>
                ))
              }
            </div>
          </section>

          {/* ----- Speakers ----- */}
          <div className="main">
            <section id="speakers" />
            <SpeakerCard speakers={speakers} speakersVIP={speakersVIP} />
            <section id="sponsors" />
            <Sponsor sponsors={sponsors} />
          </div>

          {/* ----- Supporting Associations ----- */}
          <section className='border section'>
            <h1 className='text-center' style={{ fontSize: '35px' }}>Supporting Associations</h1>

            <div className="container pt-5" style={{ width: '90%' }}>
              <Slider {...supportingSettings}>
                {supportings.map((supporting, index) => (
                  <div className='px-2' key={index}>
                    <img src={supporting} className='bg-white' style={{ height: '200px', width: '100%', objectFit: 'contain' }} alt='sponser' />
                  </div>
                ))}
              </Slider>
            </div>
          </section>

          {/* ----- Event Agenda ----- */}
          <section className="section">
            <h1 className="text-center section-title px-3 pb-5 mb-4">Event Agenda</h1>

            <table className="eventAgendaTable table table-bordered table-striped table-hover mx-auto shadow-sm">
              <thead>
                <tr className="text-white bg-primary">
                  <th className="text-center" style={{ fontSize: '20px' }}>Time</th>
                  <th style={{ fontSize: '20px' }}>Session</th>
                </tr>
              </thead>

              <tbody>
                {
                  eventAgendas.map((agenda, index) => (
                    <tr key={index}>
                      <td className="text-center px-3 px-sm-4 px-md-5" style={{ fontSize: '20px', minWidth: '200px' }}>
                        <div dangerouslySetInnerHTML={{ __html: agenda.time }}></div>
                      </td>
                      <td style={{ fontSize: '16px' }}>
                        <div dangerouslySetInnerHTML={{ __html: agenda.session }}></div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>

            <div className="text-center mt-5">
              <Button className="px-3 py-2 rounded-3" color="primary" href="/registration/download-agenda" >
                <b className="m-0 text-600" style={{ color: "#fff", letterSpacing: '1px', textTransform: 'none', fontSize: '1rem', }}>Download Agenda</b>
              </Button>
            </div>
          </section>

          <Footer />
        </main>
      </div>
    </>
  );
}

export default Home;
